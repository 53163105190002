/* @font-face
  font-family: 'ProximaNovaRegular'
  src: url('../fonts/DIN-RegularAlternate.woff') format("woff"), url('../fonts/DIN-RegularAlternate.ttf') format("truetype")
  font-weight: normal
  font-style: normal */
@font-face {
  font-family: 'Myriad Pro Condensed';
  src: url('../fonts/MyriadPro-Cond.eot');
  src: url('../fonts/MyriadPro-Cond.eot?#iefix') format("embedded-opentype"), url('../fonts/MyriadPro-Cond.woff2') format("woff2"), url('../fonts/MyriadPro-Cond.woff') format("woff"), url('../fonts/MyriadPro-Cond.ttf') format("truetype"), url('../fonts/MyriadPro-Cond.svg#MyriadPro-Cond') format("svg");
  font-weight: normal;
  font-style: normal; }
.item_art.col-md-5 {
  opacity: 0; }
body {
  margin: 0 auto;
  padding: 0;
  font-family: "Myriad Pro";
  font-size: 14px;
  #header {
    margin-top: 30px;
    .logo {
      font-size: 19px;
      font-family: "Myriad Pro Condensed";
      text-align: left;
      color: #8c8c8c;

      p {
        padding-top: 5px; } }
    .adress {
      margin-top: 10px;
      .phone_h {
        font-size: 25px;
        font-family: "Myriad Pro Condensed";
        color: #8c8c8c;
        span.green {
          color: #93d201; } }
      .adress_h {
        font-family: "Myriad Pro Condensed";
        font-size: 17px;
        color: #8c8c8c;
        text-align: left;
        letter-spacing: 0.5px; } }
    .cart {
      background-image: url("../images/cart.png");
      background-repeat: no-repeat;
      background-position: 12% center;
      -webkit-background-size: {}
      background-size: 28%;
      margin-top: 5px;
      padding: 15px;
      padding-left: 130px;
      border: 1px solid #f3f3f3;
      background-color: rgba(255,255,255,0.5);
      border-radius: 5px;
      .cart_title {
        font-family: "Myriad Pro Condensed";
        font-size: 18px;
        color: #93d201;
        text-align: left;
        margin: 2px auto; }
      .cart_subtitle {
        text-align: left;
        font-family: "Myriad Pro Condensed";
        font-size: 16px;
        color: #4b4b4b; }
      .cart_btn {
        background: linear-gradient(to bottom, #91cd02 0%,#82b802 50%,#76a802 100%);
        color: #fff;
        font-family: "Myriad Pro Condensed";
        font-size: 15px;
        text-align: center;
        padding: 3px 10px;
        margin: 2px 0;
        width: 60%;
        cursor: pointer;
        &:hover {
          background: linear-gradient(to top, #91cd02 0%,#82b802 50%,#76a802 100%); } } } }
  #menu {
    padding: 30px 0 0 0;
    .container {
      position: relative; }
    .leaf {
      background-image: url(../images/leaf.png);
      background-repeat: no-repeat;
      background-color: white;
      position: absolute;
      width: 90px;
      height: 90px;
      right: -30px;
      top: -65px;
      z-index: -9; }
    .navbar-default {
      background: linear-gradient(to bottom, #fff 0%,#f8f8f8 50%,#f2f2f2 100%);
      border: 1px solid #f7f7f7;
      .navbar-nav>li>a {
        font-family: "Myriad Pro Condensed";
        font-size: 20px;
        color: #4b4b4b;
        padding-left: 15px; } } }
  #banner {
    .container {
      .slider {
        background: linear-gradient(to bottom, #fff 0%,#f8f8f8 50%,#f2f2f2 100%);
        border-radius: 5px;
        border: 1px solid #f7f7f7;
        padding: 15px; }
      .slide_title {
        font-family: "Myriad Pro Condensed";
        font-size: 38px;
        text-align: left;
        text-transform: uppercase;
        color: #93d201;
        text-shadow: none; }
      .slide_subtitle {
        font-family: "Myriad Pro Condensed";
        font-size: 20px;
        color: #4b4b4b;
        text-shadow: none;
        text-align: left; }
      .slide_txt {
        font-family: "Myriad Pro Condensed";
        font-size: 20px;
        text-shadow: none;
        text-align: left;
        color: #747474; }
      .right,.left {
        background: none;
        opacity: 1; }
      .glyphicon-chevron-left,.glyphicon-chevron-right {
        background-image: url("../images/btn.png");
        background-position: center;
        background-repeat: no-repeat;
        width: 35px;
        height: 35px;
        opacity: 1;
        right: 20px;
        &:before {
          content: none; } }
      .slide_img {
        margin-left: 5%; }
      .carousel-caption {
        right: 0%;
        left: 60%;
        padding-bottom: 0px; }
      .glyphicon-chevron-left {
        transform: scale(-1,1);
        left: 20px; }
      .right {
        transform: none; }
      .plus {
        margin-right: 0;
        padding-right: 0;
        .description {
          background-color: #f4f4f4;
          border: 1px solid #e5e5e5;
          border-radius: 5px;
          margin: 0px auto 5px auto;
          padding: 10px;
          img {
            float: left;
            padding: 6px 10px 5px 25px; }
          &:last-child {
            img {
              padding-top: 5px;
              width: auto; } }
          .plus_title {
            padding-left: 90px;
            font-family: "Myriad Pro Condensed";
            font-size: 16px;
            text-transform: uppercase;
            color: #555555; }
          .plus_subtitle {
            padding-left: 90px;
            font-family: "Myriad Pro Condensed";
            color: #939393;
            font-size: 14px; } } } } }
  #content1 {
    margin-top: 20px;
    .container {
      .col-md-12 {
        margin: 0;
        padding: 0; }
      .content_title {
        font-family: "Myriad Pro Condensed";
        font-size: 30px;
        text-align: left;
        text-transform: uppercase;
        color: #80b800;
        background-image: url("../images/leaf.png");
        background-position: right center;
        background-repeat: no-repeat;
        background-size: 40px;
        width: auto !important;
        padding: 8px 55px 0px 0;
        margin-bottom: 35px; }
      .items {
        .item {
          border: 2px solid #cdcdcd;
          border-radius: 5px;
          margin: 0 2%;
          width: 22%;
          padding-bottom: 15px;
          &:hover {
            margin-top: -40px;
            margin-bottom: 0;
            .item_btn.col-md-12 {
              display: block; } } }
        .item:first-child {
          margin-left: 0; }
        .item:last-child {
          margin-right: 0; }
        .item_btn.col-md-12 {
          padding-top: 10px !important;
          display: none; }
        .cart_btn {
          float: left;
          width: 48%;
          margin: 0 1%;
          text-align: center;
          font-family: "Myriad Pro Condensed";
          font-size: 17px;
          color: #4b4b4b; }
        .add {
          background: -webkit-linear-gradient(top, #91cd02 0%, #82b802 50%, #76a802 100%);
          background: linear-gradient(to bottom, #91cd02 0%, #82b802 50%, #76a802 100%);
          color: #fff;
          font-family: "Myriad Pro Condensed";
          font-size: 15px;
          text-align: center;
          border-radius: 3px;
          padding: 3px 10px;
          cursor: pointer; }
        .one_click {
          background: -webkit-linear-gradient(top, #f4f4f4 0%, #eeeeee 50%, #e8e8e8 100%);
          background: linear-gradient(to bottom, #e8e8e8 0%, #eeeeee 50%, #f4f4f4 100%);
          border-radius: 3px;
          padding: 2px 5px;
          cursor: pointer; }
        img {
          display: block;
          margin: 0  auto;
          padding: 20px; }
        .item_description {
          width: 100%;
          height: auto;
          position: relative;
          border-top: 1px solid #e5e5e5;
          padding: 15px 0;
          .item_title {
            font-family: "Myriad Pro Condensed";
            font-size: 20px;
            color: #4b4b4b;
            margin-bottom: 10px; }
          .item_art {
            padding: 0;
            text-align: left;
            color: #9d9d9d;
            font-family: "Myriad Pro Condensed";
            font-size: 16px;
            margin-top: 12px; }
          .item_price {
            color: #8cc802;
            font-family: "Myriad Pro Condensed";
            font-size: 30px;
            text-align: left; } } } } }


  #content2 {
    margin-top: 20px;
    .container {
      .col-md-12 {
        margin: 0;
        padding: 0; }
      .content_title {
        font-family: "Myriad Pro Condensed";
        font-size: 30px;
        text-align: left;
        text-transform: uppercase;
        color: #80b800;
        background-image: url("../images/leaf.png");
        background-position: right center;
        background-repeat: no-repeat;
        background-size: 40px;
        width: auto !important;
        padding: 8px 55px 0px 0;
        margin-bottom: 20px; }
      .special_item {
        border: 1px solid #cdcdcd;
        border-radius: 5px;
        background-color: #f4f4f4;
        width: 31%;
        padding: 20px;
        margin: 0 1.666666%; }
      .special_item:first-child {
        margin-left: 0; }
      .special_item:last-child {
        margin-right: 0; }
      img {
        float: right; }
      p {
        float: left;
        font-family: "Myriad Pro Condensed";
        text-align: left;
        font-size: 22px;
        padding-top: 5px;
        color: #4b4b4b;
        position: absolute;
        width: 60%;
        span.green {
          text-transform: uppercase;
          color: #80b602;
          font-family: "Myriad Pro Condensed";
          font-size: 27px;
          text-align: left;
          display: block;
          padding-top: 10px; } } } }

  #content3 {
    margin-top: 20px;
    .container {
      .col-md-12 {
        margin: 0;
        padding: 0; }
      .content_title {
        font-family: "Myriad Pro Condensed";
        font-size: 30px;
        text-align: left;
        text-transform: uppercase;
        color: #80b800;
        background-image: url("../images/leaf.png");
        background-position: right center;
        background-repeat: no-repeat;
        background-size: 40px;
        width: auto !important;
        padding: 8px 55px 0px 0;
        margin-bottom: 0px; }
      .item_btn.col-md-12 {
        padding-top: 10px !important;
        display: none; }
      .items {
        .item:first-child:hover {
          margin-bottom: 0 !important; }
        .item:nth-child(4n+4):hover {
          margin-bottom: 2px; }
        .item {
          border: 2px solid #cdcdcd;
          border-radius: 5px;
          margin: 15px 2%;
          width: 22%;
          padding-bottom: 20px;
          &:hover {
            margin-top: -10px;
            margin-bottom: 0;
            .item_btn.col-md-12 {
              display: block; } } }
        .item:nth-child(4n+1) {
          margin-left: 0; }
        .item:nth-child(4n+4) {
          margin-right: 0; }
        .cart_btn {
          float: left;
          width: 48%;
          margin: 0 1%;
          text-align: center;
          font-family: "Myriad Pro Condensed";
          font-size: 17px;
          color: #4b4b4b; }
        .add {
          background: -webkit-linear-gradient(top, #91cd02 0%, #82b802 50%, #76a802 100%);
          background: linear-gradient(to bottom, #91cd02 0%, #82b802 50%, #76a802 100%);
          color: #fff;
          font-family: "Myriad Pro Condensed";
          font-size: 15px;
          text-align: center;
          border-radius: 3px;
          padding: 3px 10px;
          cursor: pointer; }
        .one_click {
          background: -webkit-linear-gradient(top, #f4f4f4 0%, #eeeeee 50%, #e8e8e8 100%);
          background: linear-gradient(to bottom, #e8e8e8 0%, #eeeeee 50%, #f4f4f4 100%);
          border-radius: 3px;
          padding: 2px 5px;
          cursor: pointer; }

        img {
          display: block;
          margin: 0  auto;
          padding: 10px; }
        .item_description {
          width: 100%;
          height: auto;
          position: relative;
          border-top: 1px solid #e5e5e5;
          padding: 15px 0;
          .item_title {
            font-family: "Myriad Pro Condensed";
            font-size: 20px;
            color: #4b4b4b;
            margin-bottom: 10px; }
          .item_art {
            padding: 0;
            text-align: left;
            color: #9d9d9d;
            font-family: "Myriad Pro Condensed";
            font-size: 16px;
            margin-top: 12px; }
          .item_price {
            color: #8cc802;
            font-family: "Myriad Pro Condensed";
            font-size: 30px;
            text-align: left; } } } } }
  #article {
    margin-top: 20px;
    .container {

      .col-md-12 {
        margin: 0;
        padding: 0; }
      .content_title {
        font-family: "Myriad Pro Condensed";
        font-size: 30px;
        text-align: left;
        text-transform: uppercase;
        color: #80b800;
        background-image: url("../images/leaf.png");
        background-position: right center;
        background-repeat: no-repeat;
        background-size: 40px;
        width: auto !important;
        padding: 8px 55px 0px 0;
        margin-bottom: 20px; }
      .article_description {
        font-family: "Myriad Pro Condensed";
        font-size: 17px;
        text-align: left;
        color: #6f6f6f;
        padding-bottom: 20px; } } }
  #footer {
    background-color: #f4f4f4;
    .footer_block {
      width: 20%;
      border: 1px dotted #aaa;
      border-top: none;
      border-bottom: none;
      height: 100%;
      min-height: 150px;
      margin-bottom: 20px;
      margin-top: 15px;
      border-left: none;
      padding-left: 20px; }
    .footer_block:last-child {
      border-right: none; }

    .title_block {
      font-family: "Myriad Pro Condensed";
      font-size: 19px;
      text-align: left;
      padding-bottom: 10px;
      padding-top: 5px;
      color: #4b4b4b; }
    .content_block {
      font-family: "Myriad Pro Condensed";
      font-size: 17px;
      color: #6f6f6f;
      ul {
        list-style-type: circle;
        li {
          color: #83b905;
          span {
            color: #6f6f6f; } } }
      .address {
        background-image: url("../images/mark.png");
        background-repeat: no-repeat;
        background-position: left 10%;
        padding-left: 20px;
        margin-left: 10px; }
      .phone {
        background-image: url("../images/phone.png");
        background-repeat: no-repeat;
        background-position: left 25%;
        padding-left: 20px;
        margin-left: 10px; }
      .mail {
        background-image: url("../images/mail.png");
        background-repeat: no-repeat;
        background-position: left 40%;
        padding-left: 20px;
        margin-left: 10px; }
      a.fb, a.tw, a.ggl {
        display: block;
        width: 30px;
        height: 30px;
        -webkit-background-size: {}
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center; }
      a.fb {
        background-image: url("../images/fb.png");
        &:hover {
          background-image: url("../images/fb_hover.png"); } }
      a.ggl {
        background-image: url("../images/google.png");
        &:hover {
          background-image: url("../images/google_hover.png"); } }
      a.tw {
        background-image: url("../images/tw.png");
        &:hover {
          background-image: url("../images/tw_hover.png"); } } } }
  #bread {
    .breadcrumb {
      background-color: transparent;
      padding-top: 0;
      border-radius: 0;
      border-bottom: 1px solid #f3f3f3;
      li {
        color: #8c8c8c;
        a {
          color: #8c8c8c; } }
      li.active {
        color: #79ac00 !important; } }
    .breadcrumb>li+li:before {
      content: "\00bb"; } }
  #cart {
    .cart_title {
      font-family: "Myriad Pro Condensed";
      font-size: 32px;
      text-align: left;
      color: #7fb800; }
    .table_block {
      .border {
        border-radius: 10px; }
      .table {
        tr:hover {
          background-color: #f5f5f5;
          cursor: pointer; }
        th {
          font-family: "Myriad Pro Condensed";
          font-size: 20px;
          text-align: left;
          font-weight: normal;
          color: #4b4b4b;
          border-bottom: 1px; }

        td {
          line-height: 20px;
          vertical-align: middle;
          font-family: "Myriad Pro Condensed";
          font-size: 19px;
          color: #8c8c8c;
          font-weight: normal;
          input.count {
            font-size: 17px;
            padding: 3px 5px;
            background: linear-gradient(to bottom, #fff 0%,#f8f8f8 50%,#f2f2f2 100%);
            border-radius: 5px;
            border: 1px solid #d1d1d1;
            width: 60px;
            color: #4b4b4b;
            &::-webkit-input-placeholder {
              color: #4b4b4b; } }
          p {
            padding: 15px 0 0 10px; }
          img {
            float: left;
            margin-right: 10px; }
          span.glyphicon.glyphicon-remove {
            border: 1px solid #d1d1d1;
            border-radius: 5px;
            width: 30px;
            height: 30px;
            display: block;
            font-weight: normal;
            color: #d1d1d1;
            padding: 4px;
            font-size: 20px;
            margin-left: 10px;
            &:hover {
              color: #ce2727;
              border-color: #ce2727; } } } } }
    .result {
      padding-top: 10px;
      border-top: 1px solid #f3f3f3;
      margin: 10px 0;
      height: auto;
      min-height: 45px;
      .r_txt {
        font-family: "Myriad Pro Condensed";
        font-size: 20px;
        text-align: left;
        height: 40px;
        padding: 5px 15px 0 0;
        color: #4b4b4b;
        float: left; }
      .r_res {
        font-family: "Myriad Pro Condensed";
        text-align: left;
        font-size: 24px;
        padding-top: 2px;
        color: #85bd01;
        float: left; } } }
  #contact {
    .contact_title {
      background-color: #f7f7f7;
      border: 1px solid #ebebeb;
      border-radius: 3px;
      padding: 5px 20px;
      font-family: "Myriad Pro Condensed";
      font-size: 20px;
      color: #4b4b4b; }
    .contact_content {
      padding: 0;
      margin: 20px 0;
      .form-group {
        width: 23%;
        margin: 0 13px;
        &:first-child {
          margin-left: 0; }
        &:last-child {
          margin-right: 0; }
        input {
          width: 100%;
          height: 38px;
          padding-top: 12px;
          &::-webkit-input-placeholder {
            font-family: "Myriad Pro Condensed";
            font-size: 18px;
            text-align: left;
            color: #8c8c8c; } } }
      .pay {
        .checkbox {
          float: left;
          font-family: "Myriad Pro Condensed";
          font-size: 18px;
          color: #8c8c8c;
          font-weight: normal;
          display: block;
          margin: 5px;
          height: 30px;
          width: auto;
          min-width: 180px;
          &:nth-child(4) {
            clear: left; } } }
      .send {
        .checkbox {
          float: left;
          font-family: "Myriad Pro Condensed";
          font-size: 18px;
          color: #8c8c8c;
          font-weight: normal;
          display: block;
          margin: 5px;
          height: 30px;
          width: auto;
          min-width: 220px;
          &:nth-child(3) {
            clear: left; } } } }
    .contact_btn {
      margin: 0px 0 30px 0;
      padding: 0;
      .accept_btn {
        background: linear-gradient(to bottom, #91cd02 0%,#82b802 50%,#76a802 100%);
        color: #fff;
        font-family: "Myriad Pro Condensed";
        font-size: 20px;
        text-align: center;
        width: 155px;
        padding: 3px 20px;
        border-radius: 3px;
        float: left;
        cursor: pointer;
        &:hover {
          background: linear-gradient(to top, #91cd02 0%,#82b802 50%,#76a802 100%); } }
      .continue_btn {
        background: linear-gradient(to bottom, #fff 0%,#f8f8f8 50%,#f3f3f3 100%);
        font-family: "Myriad Pro Condensed";
        font-size: 20px;
        text-align: center;
        color: #8c8c8c;
        width: 180px;
        padding: 3px 20px;
        float: left;
        margin: 0 10px;
        border: 1px solid #f7f7f7;
        border-radius: 3px;
        cursor: pointer;
        &:hover {
          background: linear-gradient(to top, #fff 0%,#f8f8f8 50%,#f3f3f3 100%);
          color: #000; } } } }

  #product {
    .container {
      .image_product {
        border: 2px solid #d1d1d1;
        border-radius: 5px;
        padding: 25px 5px;
        margin: 0 auto;
        text-align: center; }
      .description_product {
        padding-left: 35px; }
      .product_title {
        font-family: "Myriad Pro Condensed";
        font-size: 32px;
        text-align: left;
        color: #7fb800; }
      .product_art {
        font-family: "Myriad Pro Condensed";
        text-align: left;
        color: #9d9d9d;
        margin: 10px 0;
        font-size: 18px; }
      .product_options {
        .weight, .amount {
          font-family: "Myriad Pro Condensed";
          font-size: 18px;
          text-align: left;
          color: #4b4b4b; }
        .amount {
          width: 100%;
          .ok_block, .no_block {
            img {
              padding: 0px 10px 5px 10px; } }
          .no_block {
            img {
              padding-bottom: 3px !important; } }
          p {
            display: block;
            float: left; } } }
      .product_recommend {
        clear: left;
        font-family: "Myriad Pro Condensed";
        text-transform: uppercase;
        font-size: 20px;
        margin: 40px 0 20px 0;
        text-align: left;
        .rec_txt {
          text-align: left;
          font-size: 18px;
          color: #9d9d9d;
          text-transform: none; } }
      .product_cost {
        background-color: #f7f7f7;
        border: 1px solid #ebebeb;
        border-radius: 3px;
        padding: 5px 20px;
        min-height: 55px;
        .price {
          font-family: "Myriad Pro Condensed";
          font-size: 22px;
          color: #4b4b4b;
          span.green {
            font-size: 35px;
            color: #8cc701; } }
        .count {
          input {
            font-size: 17px;
            padding: 3px 5px;
            background: linear-gradient(to bottom, #fff 0%,#f8f8f8 50%,#f2f2f2 100%);
            border-radius: 5px;
            border: 1px solid #d1d1d1;
            width: 60px;
            height: 30px;
            margin-top: 10px;
            color: #4b4b4b;
            &::-webkit-input-placeholder {
              color: #4b4b4b; } } }
        .cart_btn {
          background: linear-gradient(to bottom, #91cd02 0%,#82b802 50%,#76a802 100%);
          color: #fff;
          font-family: "Myriad Pro Condensed";
          font-size: 15px;
          text-align: center;
          padding: 3px 10px;
          margin: 11px 0;
          width: 100px;
          border-radius: 3px;
          cursor: pointer;
          &:hover {
            background: linear-gradient(to top, #91cd02 0%,#82b802 50%,#76a802 100%); } } }
      .social {
        text-align: left;
        margin-top: 20px;
        p {
          float: left;
          margin-right: 10px;
          font-size: 18px;
          color: #4b4b4b; }
        .links {
          clear: left;
          a.social_link {
            display: block;
            margin: 0 2px;
            width: 20px;
            float: left; } } }
      .main_desc {
        margin-top: 40px;
        padding: 0;
        .nav-tabs {
          li {
            background: linear-gradient(to bottom, #fff 0%, #f8f8f8 50%, #f2f2f2 100%);
            border: 1px solid #ebebeb;
            font-size: 18px;
            border-radius: 3px 3px 0 0;
            margin: 0 2px;
            color: #4b4b4b;
            &:hover {
              background: linear-gradient(to bottom, #fff 0%, #f8f8f8 50%, #f2f2f2 100%);
              border: 1px solid #ebebeb;
              font-size: 18px;
              border-radius: 3px 3px 0 0;
              padding: 0;
              color: #4b4b4b; }
            a {
              color: #4b4b4b;
              padding: 12px 13px;
              &:hover {
                margin: 0 auto;
                background: linear-gradient(to top, #fff 0%, #f8f8f8 50%, #f2f2f2 100%);
                border: none;
                padding: 13px 15px;
                background-color: transparent; } } }
          li.active {
            background-color: #f7f7f7 !important;
            color: #7fb800;
            font-size: 18px;
            border-bottom: none;
            z-index: 9999;
            margin-bottom: -1px;
            margin-left: 0px;
            a {
              background: #f7f7f7 !important;
              border: none;
              padding: 15px 13px;
              margin: 0 auto;
              line-height: normal;
              color: #7fb800; }
            a:hover {
              background: #f7f7f7 !important; } } }
        .tab-pane {
          background-color: #f7f7f7;
          padding: 20px;
          border: 1px solid #ebebeb;
          border-top: 1px;
          margin-bottom: 30px;
          font-size: 16px;
          p.title_desc {
            font-size: 22px;
            padding-top: 20px; }
          ul {
            list-style-type: none;
            span.glyphicon.glyphicon-chevron-right {
              font-size: 12px;
              color: #83bb02;
              padding-right: 5px; } } } } } } }

/* MOBILE */

@media (max-width: 767px) and (min-width: 300px) {
  #header {
    .logo {
      width: 100%;
      margin: 0 auto;
      text-align: center;
      img {
        display: block;
        text-align: center;
        margin: 0 auto; }
      p {
        text-align: center; } }
    .adress {
      width: 100%;
      margin: 0 auto;
      text-align: center;
      .phone_h {
        text-align: center; }
      .adress_h {
        text-align: center !important; } }
    .cart {
      -webkit-background-size: {}
      background-size: 12% !important;
      background-position: 5% center !important;
      padding: 5px !important;
      padding-left: 65px !important;
      min-height: 75px;
      .cart_title {
        width: auto;
        float: left; }
      .cart_subtitle {
        float: left;
        padding-top: 3px !important;
        padding-left: 10px; }
      .cart_btn {
        float: left; } } }

  #menu {
    padding-top: 10px !important;
    .leaf {
      right: 0px !important; } }
  #banner {
    .slider {
      .carousel {
        min-height: 250px; }
      .carousel-inner {
        overflow: visible; }
      .slide_img {
        margin: 0 auto !important;
        img {
          width: 100%; } }
      .carousel-caption {
        left: 0 !important;
        top: 120px !important;
        padding: 0 25px;
        text-align: center;
        .slide_title {
          font-size: 25px;
          text-align: center; }
        .slide_subtitle {
          text-align: center; }
        .slide_txt {
          text-align: center; } }
      .glyphicon-chevron-left {
        left: -15px !important; }
      .glyphicon-chevron-right {
          right: -20px !important; } }
    .plus {
      display: none; } }


  .content_title {
    text-align: center !important; }


  #content1 {
    .item {
      width: 100% !important;
      margin: 15px auto !important;
      padding-bottom: 55px !important;
      .item_art {
        width: 45% !important;
        float: left; }
      .item_price {
        width: 50% !important;
        float: left; }

      .item_btn {
        display: block !important; } } }
  #content2 {
    .special_item {
      width: 100% !important;
      margin: 15px auto !important;
      p {
        position: relative !important;
        width: 100% !important; }
      img {
        float: none !important;
        display: block;
        margin: 0 auto;
        text-align: center; } } }
  #content3 {
    .item {
      width: 100% !important;
      margin: 15px auto !important;
      padding-bottom: 55px !important;
      .item_art {
        width: 45% !important;
        float: left; }
      .item_price {
        width: 50% !important;
        float: left; }
      .item_btn {
        display: block !important; } } }

  #footer {
    .footer_block {
      width: 100% !important;
      margin: 0 auto !important;
      border: none !important;
      min-height: 100px !important;
      .col-md-3 {
        width: 33.3% !important;
        float: left; } } }

  /* page2 */
  .table_block .table td p {
    padding: 15px 0 0 65px !important; }
  #contact {
    .form-group {
      width: 100% !important;
      margin: 20px auto !important; }
    .contact_content {
      .pay {
        min-height: 220px; } }
    .continue_btn {
      margin: 10px 0 !important; } }

  /* page3 */
  #product {
    .image_product {
      img {
        width: 100% !important; } }
    .description_product {
      width: 100% !important;
      margin: 0 auto !important;
      padding: 10px !important;
      .product_title {
        line-height: 30px; }
      .product_options {
        .weight {
          margin: 10px 0 !important; } }
      .product_recommend {
        margin-top: 5px !important; }
      .product_cost {
        padding: 0 !important;
        min-height: 100px !important;
        .count {
          width: 60%;
          float: left; }
        .cart_btn {
          float: left; } } }
    .main_desc {
      width: 105%;
      left: -2%;
      ul.nav-tabs {
        padding-left: 0 !important; }
      ul {
        padding-left: 15px !important; }
      #desc {
        font-size: 14px !important; }
      .title_desc {
        font-size: 16px !important; } }
    .main_desc .nav-tabs li, li.active {
      font-size: 14px !important; }
    .main_desc .nav-tabs li.active a {
      padding: 11px 5px !important; }
    .main_desc .nav-tabs li a {
      padding: 8px 2px !important; } } }

/* PAD */

@media (max-width: 1000px) and (min-width: 768px) {
  #header {
    .logo {
      width: 30%;
      position: relative;
      float: left;
      img {
        width: 100%; }
      p {
        font-size: 18px;
        text-align: center; } }
    .adress {
      width: 30%;
      position: relative;
      float: left; }
    .cart {
      float: left;
      width: 40%; } }
  #menu {
    .leaf {
      right: 0 !important; } }
  #banner {
    .slide_img {
      margin-left: 5% !important;
      img {
        width: 55%; } }
    .carousel-caption {
      .slide_title {
        font-size: 25px !important; }
      .slide_subtitle {
        padding-right: 20px !important; } }
    .plus {
      padding: 0 !important;
      .description {
        width: 32%;
        margin-right: 10px !important;
        float: left;
        margin-top: 10px !important; }
      .description:last-child {
        margin-right: 0 !important; } } }
  .content_title {
    text-align: center !important; }
  #content1 {
    .item {
      width: 40% !important;
      float: left;
      margin: 10px 5% !important;
      .item_btn {
        display: block !important; } } }
  #content2 {
    .special_item {
      width: 48% !important;
      float: left;
      margin: 10px 1% !important; } }
  #content3 {
    .item {
      width: 40% !important;
      float: left;
      margin: 10px 5% !important;
      .item_btn {
        display: block !important; } } }
  #footer {
    padding: 0 15px !important;
    margin: 0 !important;
    .container {
      margin: 0 !important;
      padding: 0 !important; }
    .footer_block {
      width: 25%;
      float: left;
      padding: 0 5px !important;
      border: none !important;
      ul {
        padding-left: 5px; }
      .address, .phone, .mail {
        font-size: 14px !important; }
      .col-md-3 {
        width: 32.3% !important;
        float: left; } } }
  /* page2 */

  #contact {
    .form-group {
      width: 45% !important;
      margin: 10px 5px !important; }
    .pay {
      min-height: 80px; }
    .contact_btn {
      float: left;
      top: 10px; } }
  /* page3 */

  #product {
    .product_cost {
      min-height: 110px !important;
      .count {
        width: auto !important;
        float: left; }
      .cart_btn {
        width: auto;
        float: left; } } } }

